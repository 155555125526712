<template>
  <v-form ref="form">
    <v-file-input
      label="Dossier d'inscription"
      :value="student.file"
      @change="updateStudent('file', $event)"
      required
      accept="application/pdf"
      :rules="rules.file"
      hint="Uploadez votre dossier d'inscription au format PDF"
      persistent-hint
      show-size
      prepend-icon=""
      append-icon="mdi-file-pdf"
      filled
    ></v-file-input>
  </v-form>
</template>

<script>
import { enfStudentFormRules, student } from '@/mixins/dataMixin';
import { validate, updateStudent } from '@/mixins/methodsMixin';

export default {
  name: 'RegistrationForm5',

  mixins: [
    enfStudentFormRules,
    validate,
    updateStudent,
    student,
  ],
};
</script>
