<template>
  <v-card
    max-width="500"
    class="mx-auto"
    outlined
  >
    <v-card-title class="justify-center">
      <div class="mb-2">{{ currentTitle.text }}</div>

      <v-img
        :src="require(`@/assets/${currentTitle.picture}.svg`)"
        height="100"
        contain
      ></v-img>
    </v-card-title>

    <v-window v-model="step" touchless>
      <v-window-item :value="1">
        <v-card-text>
          <registration-form-1
            ref="step1Form"
            :countries="countries"
            :countriesAreLoading="countriesAreLoading"
            :instanceType="instanceType"
          ></registration-form-1>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
        <v-card-text>
          <registration-form-2
            ref="step2Form"
            :instanceType="instanceType"
          ></registration-form-2>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="3">
        <v-card-text>
          <registration-form-3
            ref="step3Form"
            :countries="countries"
            :countriesAreLoading="countriesAreLoading"
            :instanceType="instanceType"
          ></registration-form-3>
        </v-card-text>
      </v-window-item>

      <v-window-item
        v-if="instanceType === 'enf' || instanceType === 'enfv'"
        :value="4"
      >
        <v-card-text>
          <registration-form-4 ref="step4Form"></registration-form-4>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="5">
        <v-card-text>
          <registration-form-5 ref="step5Form"></registration-form-5>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="6">
        <v-card-text class="text-center">
          <div class="text-body-1">Envoyer votre demande d'inscription à Condorcet ?</div>

          <div class="text-caption text--secondary">
            En cliquant sur "envoyer", il ne sera plus possible de revenir sur votre demande.
            <br>
            Assurez-vous que les informations reprises dans le formulaire soient correctes.
          </div>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="7">
        <v-card-text class="text-center">
          <div class="text-body-1">Votre demande d'inscription a bien été envoyée</div>

          <!-- <div class="text-caption text--secondary d-flex align-center justify-center">
            <v-icon color="warning" class="mr-1" small>mdi-email</v-icon>

            <span>Vous allez recevoir un e-mail sur {{ $store.state.student.student.email }}</span>
          </div> -->

          <div class="text-caption text--secondary">
            Attention, une nouvelle demande d'inscription de votre part pour ce cursus ou un autre
            résulterait en l'annulation de toutes vos demandes d'inscription.
            <br>
            En effet, une seule inscription est autorisée.
          </div>
        </v-card-text>
      </v-window-item>
    </v-window>

    <template v-if="step !== 7">
      <v-divider></v-divider>

      <v-card-actions class="justify-space-between">
        <v-btn
          color="secondary"
          :disabled="step === 1 || isSendingForm || submitIsForbidden"
          text
          @click="goBack"
        >
          Retour
        </v-btn>

        <v-item-group
          v-model="step"
          mandatory
        >
          <v-item
            v-for="n in 6"
            :key="`btn-${n}`"
            v-slot="{ active }"
            disabled
            :value="n"
          >
            <v-btn
              x-small
              :input-value="active"
              icon
              :color="n <= step ? 'primary' : ''"
              v-show="n !== 4 ? true : (instanceType === 'enf' || instanceType === 'enfv')
              ? true : false"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>

        <v-btn
          color="primary"
          depressed
          @click="goNext"
          :loading="isSendingForm"
          :disabled="submitIsForbidden"
        >
          {{ step === 6 ? 'Envoyer' : 'Suivant' }}
        </v-btn>
      </v-card-actions>
    </template>

    <v-overlay :value="instanceType === 'enfv' && studentDataAreLoading">
      <div class="d-flex flex-column">
        <v-progress-circular
          indeterminate
          size="64"
          class="mx-auto"
        ></v-progress-circular>

        <span class="pt-2">Chargement de vos données</span>
      </div>
    </v-overlay>
  </v-card>
</template>

<script>
import RegistrationForm1 from './RegistrationForm1.vue';
import RegistrationForm2 from './RegistrationForm2.vue';
import RegistrationForm3 from './RegistrationForm3.vue';
import RegistrationForm4 from './RegistrationForm4.vue';
import RegistrationForm5 from './RegistrationForm5.vue';

export default {
  name: 'RegistrationCard',

  components: {
    'registration-form-1': RegistrationForm1,
    'registration-form-2': RegistrationForm2,
    'registration-form-3': RegistrationForm3,
    'registration-form-4': RegistrationForm4,
    'registration-form-5': RegistrationForm5,
  },

  props: {
    instance: Object,
    selectedCursus: String,
    cursusId: Number,
    instanceType: String,
  },

  data: () => ({
    step: 1,
    countriesAreLoading: false,
    isSendingForm: false,
    studentDataAreLoading: true,
    submitIsForbidden: false,
  }),

  async created() {
    if (!this.countries.length) await this.getCountries();
    if (this.instanceType === 'enfv') this.importStudentData();
  },

  computed: {
    currentTitle() {
      switch (this.step) {
        case 1: return { text: 'Identité', picture: 'undraw_personal_information_re_vw8a' };
        case 2: return { text: 'Contact', picture: 'undraw_contact_us_15o2' };
        case 3: return { text: 'Adresse', picture: 'undraw_My_current_location_re_whmt' };
        case 4: return { text: 'Parcours scolaire', picture: 'undraw_education_f8ru' };
        case 5: return { text: 'Pièce jointe', picture: 'undraw_attached_file_n4wm' };
        case 6: return { text: 'Confirmation', picture: 'undraw_confidential_letter_w6ux' };
        default: return { text: 'Succès', picture: 'undraw_well_done_i2wr' };
      }
    },

    countryCodeList() {
      return this.countries.map((country) => country.code);
    },

    countries() {
      return this.$store.state.countries;
    },
  },

  methods: {
    async importStudentData() {
      try {
        this.studentDataAreLoading = true;
        // get an access token if student is veteran
        await this.$store.dispatch('getAccessToken');
        const response = await this.$http.get(`guest/misc-get-data-from-gestac.php?instance_id=${this.instance.id}`);
        Object.keys(response.data).forEach((key) => {
          if ((key === 'country' || key === 'nationality') && !this.countryCodeList.includes(response.data[key])) return;
          this.$store.commit('student/SET_STUDENT', { key, value: response.data[key] });
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.studentDataAreLoading = false;
      }
    },

    async getCountries() {
      this.countriesAreLoading = true;
      try {
        const response = await this.$http.get('guest/getCountries.php');
        this.$store.commit('SET_COUNTRIES', response.data);
      } catch (error) {
        this.$store.commit('SET_NOTIFICATION', {
          isVisible: true,
          text: 'Impossible de récupérer la liste des pays, rechargez la page',
          color: 'error',
        });
      }
      this.countriesAreLoading = false;
    },

    async sendForm() {
      let notificationText;
      let notificationColor = 'success';
      let url = 'guest/saveRegistration.php';
      try {
        this.isSendingForm = true;
        const data = new FormData();
        data.append('student', JSON.stringify(this.$store.state.student.student));
        data.append('file', this.$store.state.student.student.file);
        if (this.instanceType === 'enr') {
          data.append('instance_id', this.instance.id);
          data.append('cursus', this.selectedCursus);
          url = '/guest/saveRegistrationEnr.php';
        } else {
          data.append('cursus_id', this.cursusId);
          data.append('type', this.instanceType);
        }
        // get an access token if student is veteran
        if (this.instanceType === 'enfv') {
          await this.$store.dispatch('getAccessToken');
        }
        const response = await this.$http.post(url, data);
        notificationText = response.data;
        this.step += 1;
        this.$store.commit('student/RESET_STUDENT');
      } catch (error) {
        if (error.response?.status === 403) {
          this.submitIsForbidden = true;
          if (error.response.data !== 'Ce cursus n\'est pas disponible') {
            this.$store.commit('student/RESET_STUDENT');
          }
        }
        notificationText = error.response?.data || 'Aucune réponse du serveur';
        notificationColor = 'error';
      } finally {
        this.isSendingForm = false;
        this.$store.commit('SET_NOTIFICATION', {
          isVisible: true,
          text: notificationText,
          color: notificationColor,
        });
      }
    },

    goNext() {
      if (this.step === 6) {
        this.sendForm();
        return;
      }
      if (this.$refs[`step${this.step}Form`].validate()) {
        if (this.instanceType !== 'enf' && this.instanceType !== 'enfv' && this.step === 3) this.step += 2;
        else this.step += 1;
      }
    },

    goBack() {
      if (this.step === 5 && this.instanceType !== 'enf' && this.instanceType !== 'enfv') this.step -= 2;
      else this.step -= 1;
    },
  },
};
</script>
