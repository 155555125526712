<template>
  <v-form ref="form">
    <v-text-field
      label="Nom"
      :value="student.lastName"
      @input="updateStudent('lastName', $event)"
      required
      :rules="rules.lastName"
      validate-on-blur
      filled
    ></v-text-field>

    <v-text-field
      label="Prénom"
      :value="student.firstName"
      @input="updateStudent('firstName', $event)"
      required
      :rules="rules.firstName"
      validate-on-blur
      filled
    ></v-text-field>

    <template v-if="instanceType === 'enr'">
      <v-radio-group
        label="Autres prénoms :"
        v-model="arrayModel"
        required
        mandatory
        :row="$vuetify.breakpoint.smAndUp"
        class="my-4 pb-1 px-3"
      >
        <v-radio
          v-for="n in 5"
          :key="n"
          :label="(n - 1).toString()"
          :value="n - 1"
        ></v-radio>
      </v-radio-group>

      <v-text-field
        v-for="n in otherNamesArray.length"
        :key="n"
        :label="getOtherNameLabel(n)"
        v-model="otherNamesArray[n - 1]"
        :rules="rules.firstName"
        validate-on-blur
        filled
      ></v-text-field>
    </template>

    <v-text-field
      v-if="instanceType === 'enr'"
      label="Lieu de naissance"
      :value="student.birthPlace"
      @input="updateStudent('birthPlace', $event)"
      required
      :rules="rules.birthPlace"
      validate-on-blur
      filled
    ></v-text-field>

    <v-menu
      ref="birthDateMenu"
      v-model="birthDateMenuIsActive"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="468"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          label="Date de naissance"
          :value="student.birthDate | formatDate"
          required
          :rules="rules.birthDate"
          v-bind="attrs"
          v-on="on"
          readonly
          filled
        ></v-text-field>
      </template>

      <v-date-picker
        ref="birthDatePicker"
        :value="student.birthDate"
        @input="updateStudent('birthDate', $event)"
        :max="birthDateMax"
        :min="birthDateMin"
        @change="saveDate('birthDateMenu', $event)"
        no-title
        first-day-of-week="1"
        full-width
      ></v-date-picker>
    </v-menu>

    <v-radio-group
      v-if="instanceType !== 'para'"
      label="Sexe :"
      :value="student.gender"
      @change="updateStudent('gender', $event)"
      required
      mandatory
      row
      class="my-4 pb-1 px-3"
    >
      <v-radio
        label="M"
        value="M"
      ></v-radio>

      <v-radio
        label="F"
        value="F"
      ></v-radio>

      <!-- <v-radio
        label="X"
        value="X"
      ></v-radio> -->
    </v-radio-group>

    <v-autocomplete
      label="Nationalité"
      :value="student.nationality"
      @input="updateStudent('nationality', $event)"
      :items="countries"
      item-text="nationality"
      item-value="code"
      required
      :rules="rules.nationality"
      validate-on-blur
      :loading="countriesAreLoading"
      auto-select-first
      hide-no-data
      filled
    ></v-autocomplete>

    <v-text-field
      v-if="instanceType === 'enr'"
      label="N° de pièce d'identité"
      :value="student.idNumber"
      @input="updateStudent('idNumber', $event)"
      required
      :rules="rules.idNumber"
      validate-on-blur
      filled
    ></v-text-field>

    <v-text-field
      v-if="instanceType === 'para'"
      label="N° de pièce d'identité"
      :value="student.idNumber"
      @input="updateStudent('idNumber', $event)"
      required
      :rules="idRules"
      validate-on-blur
      filled
    ></v-text-field>
  </v-form>
</template>

<script>
import { enfStudentFormRules, student } from '@/mixins/dataMixin';
import { validate, updateStudent, saveDate } from '@/mixins/methodsMixin';

export default {
  name: 'RegistrationForm1',

  mixins: [
    enfStudentFormRules,
    validate,
    updateStudent,
    student,
    saveDate,
  ],

  props: {
    countries: Array,
    countriesAreLoading: Boolean,
    instanceType: String,
  },

  data: () => ({
    birthDateMenuIsActive: false,
    otherNamesArray: [],
  }),

  computed: {
    arrayModel: {
      get() {
        return this.otherNamesArray.length;
      },
      set(value) {
        if (value < this.otherNamesArray.length) {
          this.otherNamesArray.splice(value);
        } else {
          const diff = value - this.otherNamesArray.length;
          for (let i = 0; i < diff; i += 1) {
            this.otherNamesArray.push('');
          }
        }
      },
    },

    formattedOtherNames() {
      return this.otherNamesArray.join(',');
    },

    birthDateMax() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 16);
      return date.toISOString().substr(0, 10);
    },

    birthDateMin() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 70);
      return date.toISOString().substr(0, 10);
    },

    idRules() {
      if (!this.student.nationality || this.student.nationality !== 'BE') return this.rules.idNumber;
      // if belgian check the id number better
      return [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => {
          const matches = /^([0-9]{3})-?([0-9]{7})-?([0-9]{2})$/.exec(v);
          if (matches && matches[3] === '97') matches[3] = '00';
          return (matches && parseInt(`${matches[1]}${matches[2]}`, 10) % 97 === parseInt(matches[3], 10)) || 'Le numéro de pièce d\'identité belge est non valide';
        },
      ];
    },
  },

  watch: {
    birthDateMenuIsActive(val) {
      if (val) setTimeout(() => { this.$refs.birthDatePicker.activePicker = 'YEAR'; });
    },

    formattedOtherNames(val) {
      this.updateStudent('otherNames', val);
    },
  },

  methods: {
    getOtherNameLabel(number) {
      switch (number) {
        case 1: return 'Deuxième prénom';
        case 2: return 'Troisième prénom';
        case 3: return 'Quatrième prénom';
        case 4: return 'Cinquième prénom';
        default: return '';
      }
    },
  },
};
</script>
