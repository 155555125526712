<template>
  <v-progress-linear
    v-if="isLoading"
    color="primary"
    indeterminate
  ></v-progress-linear>
  <v-container v-else class="my-12">
    <div class="text-center">
      <h1 class="text-h4">Etudiant Non Finançable Vétéran - Inscription</h1>

      <template v-if="selectedCursus">
        <h2 class="text-subtitle-1">
          {{ selectedCursus.name }}, {{ selectedCursus.est_name}}
        </h2>

        <v-btn
          text
          color="primary"
          x-small
          @click="selectedCursus = null"
        >
          Retour à la liste des cursus
        </v-btn>
      </template>

      <h2
        v-else-if="formIsOpen"
        class="text-subtitle-1"
      >
        Attention, une seule inscription est autorisée pour l'ensemble des cursus.
      </h2>
    </div>
    <component
      :is="activeComponent"
      @select-cursus="selectedCursus = $event"
      class="mt-6"
      :cursusId="selectedCursus ? selectedCursus.id : null"
      :cursus="cursus"
      :instanceType="'enfv'"
      :instance="null"
      :selectedCursus="null"
    ></component>
  </v-container>
</template>

<script>
import CursusClosed from '@/components/guest/CursusClosed.vue';
import CursusDahuEnf from '@/components/guest/CursusDahuEnf.vue';
import RegistrationCard from '@/components/guest/RegistrationCard.vue';

export default {
  name: 'EnfV',
  components: {
    'cursus-closed': CursusClosed,
    'cursus-dahu-enf': CursusDahuEnf,
    'registration-card': RegistrationCard,
  },

  data: () => ({
    selectedCursus: null,
    isLoading: true,
    cursus: [],
    formIsOpen: true,
    instance: {},
  }),

  created() {
    this.getData();
  },

  computed: {
    activeComponent() {
      if (!this.formIsOpen) return 'cursus-closed';
      if (this.selectedCursus) return 'registration-card';
      return 'cursus-dahu-enf';
    },
  },

  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const response = await this.$http.get('guest/getData.php?type=enfv');
        this.cursus = response.data;
      } catch (error) {
        if ([403, 404].includes(error.response?.status)) this.formIsOpen = false;
        else this.$router.push({ name: 'ErrorUnexpected' });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
