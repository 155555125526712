<template>
  <v-form ref="form" @submit.prevent>
    <template v-if="instanceType !== 'dahu'">
      <v-text-field
        label="Rue et numéro"
        :value="student.street"
        @input="updateStudent('street', $event)"
        required
        :rules="rules.street"
        validate-on-blur
        filled
        placeholder="Rue Courtejoie 17 bte 1"
      ></v-text-field>

      <v-text-field
        label="Code postal"
        :value="student.postalCode"
        @input="updateStudent('postalCode', $event)"
        required
        :rules="rules.postalCode"
        validate-on-blur
        filled
      ></v-text-field>

      <v-text-field
        label="Ville"
        :value="student.city"
        @input="updateStudent('city', $event)"
        required
        :rules="rules.city"
        validate-on-blur
        filled
      ></v-text-field>
    </template>

    <v-autocomplete
      label="Pays"
      :value="student.country"
      @input="updateStudent('country', $event)"
      :items="countries"
      item-text="country_name"
      item-value="code"
      required
      :rules="rules.country"
      validate-on-blur
      :loading="countriesAreLoading"
      auto-select-first
      hide-no-data
      filled
    ></v-autocomplete>
  </v-form>
</template>

<script>
import { enfStudentFormRules, student } from '@/mixins/dataMixin';
import { validate, updateStudent } from '@/mixins/methodsMixin';

export default {
  name: 'RegistrationForm3',

  mixins: [
    enfStudentFormRules,
    validate,
    updateStudent,
    student,
  ],

  props: {
    countries: Array,
    countriesAreLoading: Boolean,
    instanceType: String,
  },
};
</script>
