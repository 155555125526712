<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title>Cursus</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      >
        <template v-slot:label>
          <span class="font-weight-bold">Rechercher</span>
        </template>
      </v-text-field>
    </v-toolbar>

    <v-data-table
      ref="table"
      :headers="headers"
      :items="cursus"
      :search="search"
      sort-by="name"
      group-by="dept_acronym"
      disable-pagination
      hide-default-footer
      @click:row="selectCursus"
      class="row-pointer"
    >
      <template v-slot:group.header="{ headers, group, isOpen, toggle }">
        <td
          :colspan="headers.length"
          class="dept-header"
          @click="toggle"
        >
          <div class="d-flex align-center">
            <v-icon> {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>

            <v-icon class="mx-6">{{ getDeptIcon(group) }}</v-icon>

            <span class="font-weight-bold">{{ getGroupFullName(group) }}</span>
          </div>
        </td>
      </template>

      <template v-slot:item.est_name="{ item }">
        <v-chip small label>{{ item.est_name.toUpperCase() }}</v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getDeptIcon } from '@/mixins/methodsMixin';

export default {
  name: 'CursusDahuEnf',

  mixins: [getDeptIcon],

  props: {
    cursus: Array,
    instanceType: String,
  },

  data: () => ({
    search: '',
    headers: [
      { text: 'NOM', value: 'name' },
      { text: 'IMPLANTATION', value: 'est_name' },
    ],
  }),

  methods: {
    selectCursus(cursus) {
      this.$emit('select-cursus', cursus);
    },

    getGroupFullName(group) {
      switch (group) {
        case 'ABC': return 'Agrobiosciences et chimie';
        case 'ART': return 'Arts appliqués';
        case 'ECO': return 'Sciences économiques, juridiques et de gestion';
        case 'LOG': return 'Sciences logopédiques';
        case 'MGT': return 'Marketing, management touristique et hôtelier';
        case 'MOT': return 'Sciences de la motricité';
        case 'PAR': return 'Santé publique';
        case 'PED': return 'Sciences de l\'enseignement';
        case 'SOC': return 'Communication, éducation et sciences sociales';
        case 'TEC': return 'Sciences et technologies';
        default: return 'Inconnu';
      }
    },
  },

  mounted() {
    const table = this.$refs.table;
    const keys = Object.keys(table.$vnode.componentInstance.openCache);
    keys.forEach((key) => { table.$vnode.componentInstance.openCache[key] = false; });
  },
};
</script>

<style scoped>

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.dept-header {
  background-color: #e6f1fc;
}

</style>
