<template>
  <v-form ref="form" @submit.prevent>
    <v-text-field
      v-if="instanceType !== 'enfv'"
      label="Adresse e-mail"
      :value="student.email"
      @input="updateStudent('email', $event)"
      required
      type="email"
      :rules="rules.email"
      validate-on-blur
      filled
    ></v-text-field>

    <v-text-field
      v-if="instanceType !== 'dahu'"
      label="N° de téléphone"
      :value="student.phoneNumber"
      @input="updateStudent('phoneNumber', $event)"
      required
      type="tel"
      :rules="rules.phoneNumber"
      validate-on-blur
      hint="Introduisez votre numéro de téléphone au format international"
      persistent-hint
      placeholder="+32472991155"
      persistent-placeholder
      filled
    ></v-text-field>
  </v-form>
</template>

<script>
import { enfStudentFormRules, student } from '@/mixins/dataMixin';
import { validate, updateStudent } from '@/mixins/methodsMixin';

export default {
  name: 'RegistrationForm2',

  mixins: [
    enfStudentFormRules,
    validate,
    updateStudent,
    student,
  ],

  props: {
    instanceType: String,
  },
};
</script>
