<template>
  <v-form ref="form">
    <v-menu
      ref="graduationDateMenu"
      v-model="graduationDateMenuIsActive"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="468"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          label="Date d'obtention du diplôme"
          :value="student.graduationDate | formatDate"
          required
          :rules="rules.graduationDate"
          v-bind="attrs"
          v-on="on"
          hint="Date d'obtention du titre d'accès aux études supérieures"
          persistent-hint
          readonly
          filled
        ></v-text-field>
      </template>

      <v-date-picker
        ref="graduationDatePicker"
        :value="student.graduationDate"
        @input="updateStudent('graduationDate', $event)"
        :max="new Date().toISOString().substr(0, 10)"
        :min="minDateDegree"
        @change="saveDate('graduationDateMenu', $event)"
        no-title
        first-day-of-week="1"
        full-width
      ></v-date-picker>
    </v-menu>

    <v-menu
      ref="equivalenceDateMenu"
      v-model="equivalenceDateMenuIsActive"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="468"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          label="Date d'obtention de l'équivalence"
          :value="student.equivalenceDate | formatDate"
          v-bind="attrs"
          v-on="on"
          hint="Date de demande ou d'obtention de l'équivalence (optionnel)"
          persistent-hint
          readonly
          filled
        ></v-text-field>
      </template>

      <v-date-picker
        ref="equivalenceDatePicker"
        :value="student.equivalenceDate"
        @input="updateStudent('equivalenceDate', $event)"
        :max="new Date().toISOString().substr(0, 10)"
        :min="minDateDegree"
        @change="saveDate('equivalenceDateMenu', $event)"
        no-title
        first-day-of-week="1"
        full-width
      ></v-date-picker>
    </v-menu>

    <v-textarea
      :label="`En ${academicYears.first}`"
      :value="student.academicBackground1"
      @input="updateStudent('academicBackground1', $event)"
      required
      maxlength="512"
      counter
      :rules="rules.academicBackground"
      validate-on-blur
      :hint="`Décrivez brièvement votre parcours scolaire de ${academicYears.first}`"
      persistent-hint
      rows="3"
      no-resize
      filled
    ></v-textarea>

    <v-textarea
      :label="`En ${academicYears.second}`"
      :value="student.academicBackground2"
      @input="updateStudent('academicBackground2', $event)"
      required
      maxlength="512"
      counter
      :rules="rules.academicBackground"
      validate-on-blur
      :hint="`Décrivez brièvement votre parcours scolaire de ${academicYears.second}`"
      persistent-hint
      rows="3"
      no-resize
      filled
    ></v-textarea>

    <v-textarea
      :label="`En ${academicYears.third}`"
      :value="student.academicBackground3"
      @input="updateStudent('academicBackground3', $event)"
      required
      maxlength="512"
      counter
      :rules="rules.academicBackground"
      validate-on-blur
      :hint="`Décrivez brièvement votre parcours scolaire de ${academicYears.third}`"
      persistent-hint
      rows="3"
      no-resize
      filled
    ></v-textarea>
  </v-form>
</template>

<script>
import { enfStudentFormRules, student } from '@/mixins/dataMixin';
import { validate, updateStudent, saveDate } from '@/mixins/methodsMixin';

export default {
  name: 'RegistrationForm4',

  mixins: [
    enfStudentFormRules,
    validate,
    updateStudent,
    student,
    saveDate,
  ],

  data: () => ({
    graduationDateMenuIsActive: false,
    equivalenceDateMenuIsActive: false,
  }),

  computed: {
    academicYears() {
      const date = new Date().getFullYear();
      return {
        first: `${date - 3} - ${date - 2}`,
        second: `${date - 2} - ${date - 1}`,
        third: `${date - 1} - ${date}`,
      };
    },

    minDateDegree() {
      if (!this.student.birthDate) return '1950-01-01';

      const date = new Date(this.student.birthDate);
      date.setFullYear(date.getFullYear() + 15);
      return date.toISOString().substr(0, 10);
    },
  },

  watch: {
    graduationDateMenuIsActive(val) {
      if (val) setTimeout(() => { this.$refs.graduationDatePicker.activePicker = 'YEAR'; });
    },

    equivalenceDateMenuIsActive(val) {
      if (val) setTimeout(() => { this.$refs.equivalenceDatePicker.activePicker = 'YEAR'; });
    },
  },
};
</script>
