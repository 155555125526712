var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Cursus")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v("Rechercher")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{ref:"table",staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.cursus,"search":_vm.search,"sort-by":"name","group-by":"dept_acronym","disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.selectCursus},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var headers = ref.headers;
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{staticClass:"dept-header",attrs:{"colspan":headers.length},on:{"click":toggle}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',[_vm._v(" "+_vm._s(isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'))]),_c('v-icon',{staticClass:"mx-6"},[_vm._v(_vm._s(_vm.getDeptIcon(group)))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getGroupFullName(group)))])],1)])]}},{key:"item.est_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(_vm._s(item.est_name.toUpperCase()))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }