export const enfStudentFormRules = {
  data: () => ({
    rules: {
      firstName: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 50 || 'Le prénom doit faire 50 caractères maximum',
        (v) => /^[ '\-.A-Za-zÀ-ÖØ-öø-ž]+$/.test(v) || 'Le prénom ne peut contenir que des lettres et \' - . ',
        (v) => !(/^[ '\-.]+$/.test(v)) || 'Le prénom ne peut pas contenir que des \' - . ',
      ],
      lastName: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 75 || 'Le nom doit faire 75 caractères maximum',
        (v) => /^[ '\-.A-Za-zÀ-ÖØ-öø-ž]+$/.test(v) || 'Le nom ne peut contenir que des lettres et \' - . ',
        (v) => !(/^[ '\-.]+$/.test(v)) || 'Le nom ne peut pas contenir que des \' - . ',
      ],
      birthDate: [
        (v) => !!v || 'Ce champ est obligatoire',
      ],
      nationality: [
        (v) => !!v || 'Ce champ est obligatoire',
      ],
      email: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 254 || 'L\'adresse email doit faire 254 caractères maximum',
        (v) => /.+@.+\..+/.test(v) || 'L\'adresse e-mail doit être valide',
      ],
      phoneNumber: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 15 || 'Le numéro de téléphone doit faire 15 caractères maximum',
        (v) => /^\+(?:[0-9] ?){6,14}[0-9]$/.test(v) || 'Le numéro de téléphone doit être au format international',
      ],
      street: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 100 || 'La rue et le numéro doivent faire 100 caractères maximum',
      ],
      postalCode: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 9 || 'Le code postal doit faire 9 caractères maximum',
      ],
      city: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 60 || 'La ville doit faire 60 caractères maximum',
      ],
      country: [
        (v) => !!v || 'Ce champ est obligatoire',
      ],
      graduationDate: [
        (v) => !!v || 'Ce champ est obligatoire',
      ],
      academicBackground: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 512 || 'Le texte doit faire 512 caractères maximum',
      ],
      file: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => !v || v.type === 'application/pdf' || 'Le document doit être au format PDF',
        (v) => !v || v.size > 0 || 'Le document PDF ne doit pas être vide',
        (v) => !v || v.size <= 50000000 || 'Le document PDF doit faire 50 MB maximum',
      ],
      birthPlace: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 60 || 'Le lieu de naissance doit faire 60 caractères maximum',
      ],
      idNumber: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 30 || 'Le numéro de pièce d\'identité doit faire 30 caractères maximum',
        (v) => /^[0-9A-Za-z]+$/.test(v) || 'Ce champs ne peut contenir que des lettres et des chiffres',
      ],
      otherNames: [
        (v) => !v || v.length <= 75 || 'Ce champ doit faire 75 caractères maximum',
        (v) => !v || /^[, '-.A-Za-zÀ-ÖØ-öø-ž]+$/.test(v) || 'Ce champ ne peut contenir que des lettres et , \' - . ',
        (v) => !v || !(/^[, '-.]+$/.test(v)) || 'Ce champ ne peut pas contenir que des , \' - . ',
      ],
      establishmentName: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 255 || 'Le nom de l\'établissement doit faire 255 caractères maximum',
      ],
      otherCursusStudRegistered: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 512 || 'Les autres cursus doivent faire 512 caractères maximum',
      ],
      otherSchoolsStudRegistered: [
        (v) => !!v || 'Ce champ est obligatoire',
        (v) => v.length <= 512 || 'Les autres Haute Ecole doivent faire 512 caractères maximum',
      ],
    },
  }),
};

export const student = {
  computed: {
    student() {
      return this.$store.state.student.student;
    },
  },
};
